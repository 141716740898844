import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/56c857c7/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We have a multitude of sites using the gatsby theme for both public and internal sites. Using this theme we’ve been able to propogate features and fixes throughout the ecosystem quickly and easily.`}</p>
    </PageDescription>
    <h2 {...{
      "id": "ibm-design-for-ai"
    }}>{`IBM Design for AI`}</h2>
    <FeatureCard title="IBM Design for AI" actionIcon="arrowRight" href="https://ibm.com/design/ai/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.52271592611084%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGklEQVQoz2NgYGD4jwszMTH9FxDg/y8iLPyfh4cXLs7IyPifn5//PxsbG4oYlI3bQDgGKkbSAGaDLEMWg2OQBC6D2NjZ/3tYG/6XFhdBEUc3HE0fbgWykuL/D00v+x/ubAYNAkZchqAaiI4ZwU5n/m+opvB/e038/3RPC4Q4sQaCFDEzM6MoFhYU+O9hqPZfWVriPyNQDjmykNWxsLDgjxQ2Ntb/mWmx/40M9P7rKcv+FxUWIhRu2MOQGeoKZyfb/5fP7f2fnhzzX1VF+b+mpjrcIEYsMY4zDGExnpUZ/3/t8hn/p01s/Z+bn/k/Ji4KnubQDcSSSlCdDHJlc0PJ//kzOv831RX/r6gu+V9aXvRfSloKxVXoBsMwAGfw514LifnNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b43779274724accdfeaa53284ce6f45f/0eda2/ai.webp 288w", "/static/b43779274724accdfeaa53284ce6f45f/460e2/ai.webp 576w", "/static/b43779274724accdfeaa53284ce6f45f/e0ca3/ai.webp 1152w", "/static/b43779274724accdfeaa53284ce6f45f/d02be/ai.webp 1728w", "/static/b43779274724accdfeaa53284ce6f45f/7a65a/ai.webp 2003w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b43779274724accdfeaa53284ce6f45f/1a057/ai.png 288w", "/static/b43779274724accdfeaa53284ce6f45f/8418d/ai.png 576w", "/static/b43779274724accdfeaa53284ce6f45f/c801a/ai.png 1152w", "/static/b43779274724accdfeaa53284ce6f45f/4d515/ai.png 1728w", "/static/b43779274724accdfeaa53284ce6f45f/826d6/ai.png 2003w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/png"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b43779274724accdfeaa53284ce6f45f/c801a/ai.png",
            "alt": "IBM Design AI",
            "title": "IBM Design AI",
            "loading": "lazy"
          }}></img>{`
      `}</picture>{`
    `}</span>
    </FeatureCard>
    <h2 {...{
      "id": "ibm-design-language"
    }}>{`IBM Design Language`}</h2>
    <FeatureCard title="IBM Design Language" actionIcon="arrowRight" href="https://design-language-website.netlify.com/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.08910891089109%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB8klEQVQoz0WS626bQBBGrVRq1aqKZTWuolSJDcaYO7vsAoblbqd9/yf6OrOp3R8jWMGcPXNZxUmMe6Qx0jRCSO9pGEJ5HlopIIMA++0W3usrhJSQhUQ/9GjaBqIQiMIT4sMeqX/A6gZLsgRZnkGInH4qII4+MmePwHERHI50QYI8F3egLjWUVnQW8E8+QneHxHOwMt2AfpwxzhdMyxXTfMUwLah1jVJqnJuOYkBNUdUtciEskINhUilkQpElVebusRqmC0w/Yb6836OlS0pK7sbFflNlQ0Ytzm2PXMp/lso+ozhBkuY4eD7Wmx9Ytd1IhguBfltDBpp+tMkM64YZjRmgqxatmaxRzoZkl8chkuMBkevA3+/wTH1ecSJD5+W/IZdcNcYCzTBZuCrPBF9Q6hKSQCo60bCOKGggis4i8OH9eqEeUhIDR+rd5f2PBXI/2frWDn4/mxF1bVCnKUraAB0FUGGAgmCp78El2OOaSu6GDyAPhsvTdWMNG9NbSy6Xoz4blDRlTRuhowiaoDKgdXFd+G9v2Dw94+HrmoGzNbhNl5NvhnwZW3ZkWWUZgbjUCIr2UtGKnWgvne1PpEmKx+0LPjGQExnCiRP1kQfDwI+4WrChvlUEKoIQFU1VCYlCFdg9bXFyHBpSgS/fN3j4/A1/AfYhPZsCPB3jAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/844f9d96fb23808ec01ebc36f8597623/0eda2/idl.webp 288w", "/static/844f9d96fb23808ec01ebc36f8597623/460e2/idl.webp 576w", "/static/844f9d96fb23808ec01ebc36f8597623/e0ca3/idl.webp 1152w", "/static/844f9d96fb23808ec01ebc36f8597623/d02be/idl.webp 1728w", "/static/844f9d96fb23808ec01ebc36f8597623/3fe5a/idl.webp 2020w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/844f9d96fb23808ec01ebc36f8597623/1a057/idl.png 288w", "/static/844f9d96fb23808ec01ebc36f8597623/8418d/idl.png 576w", "/static/844f9d96fb23808ec01ebc36f8597623/c801a/idl.png 1152w", "/static/844f9d96fb23808ec01ebc36f8597623/4d515/idl.png 1728w", "/static/844f9d96fb23808ec01ebc36f8597623/33801/idl.png 2020w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/png"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/844f9d96fb23808ec01ebc36f8597623/c801a/idl.png",
            "alt": "IBM Design Language",
            "title": "IBM Design Language",
            "loading": "lazy"
          }}></img>{`
      `}</picture>{`
    `}</span>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      